import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Layout from '@/components/common/layout';
import Container from '@/components/common/container';
import BlogCard from '@/components/blog/blog-card';
import FeaturedPost from '@/components/blog/featured-post';
import NewsletterBox from '@/components/common/newsletter-box';
import SEO from '@/components/seo';

const BlogTags = ({ data }) => {
  const posts = data.allSanityBlogPosts.nodes;
  const tagName = data.sanityBlogTags.name;

  return (
    <Layout>
      <div className="pb-14 pt-20 md:pb-24 lg:pb-32 lg:pt-28">
        <Container>
          <div>
            <h1 className="mx-auto max-w-[90%] text-center text-[30px] font-semibold leading-tight md:max-w-4xl md:text-[40px] lg:text-5xl">
              Posts tagged {tagName}
            </h1>
          </div>

          <div className="mt-8 grid grid-cols-1 gap-5 gap-y-10 md:grid-cols-2 lg:grid-cols-3">
            {posts.map((post) => {
              return <BlogCard data={post} />;
            })}
          </div>
        </Container>
        <div className="mt-12 md:mt-28">
          <NewsletterBox />
        </div>
      </div>
    </Layout>
  );
};

export default BlogTags;

export const query = graphql`
  query BlogTags($tagSlug: String) {
    sanityBlogTags(slug: { current: { eq: $tagSlug } }) {
      name
    }
    allSanityBlogPosts(
      filter: { tags: { elemMatch: { slug: { current: { eq: $tagSlug } } } } }
    ) {
      nodes {
        title
        description
        date(formatString: "MMMM DD YYYY")
        slug {
          current
        }
        author {
          name
          role
        }
        tags {
          name
          slug {
            current
          }
        }
        mainImage {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const tagName = data.sanityBlogTags.name;
  return (
    <SEO
      title={`Blog | ${tagName}`}
      description={`Blog posts tagged ${tagName}`}
    />
  );
};
