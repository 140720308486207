import React, { useState } from 'react';
import Container from '@/components/common/container';
import { useStaticQuery, graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from 'react-netlify-forms';

type FormDataTypes = {
  email: string;
};

const NewsletterBox = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataTypes>({
    mode: 'all',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
  });

  const [submitSucess, setSubmitSucess] = useState(false);

  const netlify = useNetlifyForm({
    name: 'contact-form',
    honeypotName: 'bot-field',
    onSuccess: (response, context) => {
      setSubmitSucess(true);
    },
    onFailure: (error, context) => {
      console.log('error', error);
    },
  });

  const onSubmit = (data) => netlify.handleSubmit(null, data);

  const query = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        newsletterComponent {
          title
          subtitle
          buttonLabel
          formInputLabel
        }
      }
    }
  `);

  const { title, subtitle, buttonLabel, formInputLabel } =
    query.sanitySiteSettings.newsletterComponent;

  return (
    <div>
      <Container>
        <div className="rounded-[20px] border bg-gradient-to-br from-orangey-yellow via-reddish-magenta to-purply-blue text-white">
          {submitSucess ? (
            <div className="flex min-h-[250px] items-center justify-center px-5 py-14 md:px-24">
              <h2 className="max-w-lg text-center text-xl leading-tight md:text-3xl xl:text-4xl">
                Thank you for subscribing to our newsletter!
              </h2>
            </div>
          ) : (
            <div className="flex flex-col bg-[url(/newsletter.svg)] bg-[length:200px] bg-right-top bg-no-repeat px-5 py-14 text-center md:bg-[length:300px] md:px-24 md:text-left xl:flex-row xl:justify-between xl:py-24">
              <div>
                <h2 className="text-lg md:max-w-md md:text-3xl xl:max-w-xl xl:text-4xl">
                  {title}
                </h2>
                <p className="font-outfit mt-4 text-sm font-light md:max-w-md md:text-lg">
                  {subtitle}
                </p>
              </div>

              <NetlifyFormProvider {...netlify}>
                <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
                  <Honeypot />
                  <div className="mt-8 flex flex-col items-center md:items-start">
                    <label htmlFor="email" className="sr-only">
                      {formInputLabel}
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder={formInputLabel}
                      {...register('email', {
                        required: {
                          value: true,
                          message: 'Email address is required',
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'Enter a valid email address',
                        },
                      })}
                      className="placeholder:font-outfit w-full rounded-[8px] border-2 border-white bg-white px-2 py-3 text-sm font-light text-black outline-none duration-300 placeholder:text-sm placeholder:font-light focus:border-orangey-yellow md:max-w-md xl:min-w-[400px]"
                    />
                    <div className="h-5 w-full text-left">
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => (
                          <span className="text-xs text-white">{message}</span>
                        )}
                      />
                    </div>

                    <button
                      type="submit"
                      className="font-outfit min-w-[170px] rounded-[8px] border border-white px-6 py-3 text-sm font-light duration-300 hover:border-orangey-yellow hover:bg-reddish-magenta"
                    >
                      {buttonLabel}
                    </button>
                  </div>
                </NetlifyFormComponent>
              </NetlifyFormProvider>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default NewsletterBox;
