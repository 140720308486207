import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Fade from '../common/fade';

const BlogCard = ({ data, idx }) => {
  const { title, mainImage, mainImageAlt, tags, slug, description, date } =
    data;

  return (
    <Link
      to={`/blog/${slug.current}`}
      className="group col-span-1 flex h-full flex-col rounded-md border border-purple-100"
    >
      <Fade delay={0.1 * idx} duration={0.5}>
        <div className="mb-4">
          <GatsbyImage
            alt={mainImageAlt}
            image={mainImage.asset.gatsbyImageData}
            className="h-[200px] rounded-md"
          />
        </div>
        <div className="flex h-full flex-col gap-2 px-4 pb-4">
          <span className="font-outfit text-xs font-light text-gray-600">
            {date}
          </span>
          <h3 className="text-xl duration-300 group-hover:text-purply-blue">
            {title}
          </h3>
          <p className="font-outfit text-sm font-light text-gray-500 md:text-base">
            {description}
          </p>
          <div className="mt-4 flex flex-wrap gap-3">
            {tags.map((tag, index) => {
              return (
                <span key={index}>
                  <Link
                    className="font-outfit inline-flex whitespace-nowrap rounded-lg bg-magnolia-500 px-4 py-1 text-sm font-light text-gray-700 duration-300 hover:bg-fuchsia-300"
                    to={`/blog/tags/${tag.slug.current}`}
                  >
                    {tag.name}
                  </Link>
                </span>
              );
            })}
          </div>
        </div>
      </Fade>
    </Link>
  );
};

export default BlogCard;
